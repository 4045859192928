import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { Container, Col, Row } from "react-bootstrap"

import HeaderStyles from "./header3.module.css"

/* import Img from "gatsby-image"
 */
const Header3 = ({ src_sfondo, title, link_page }) => {

  /* const src = data;  
  background: `rebeccapurple`,  
  backgroundImage: `url(${src_sfondo})`,
   paddingTop: 90,
   backgroundImage: `url(${src_sfondo}) `,
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `center`,
        backgroundAttachment: `fixed`
        marginBottom: `1.45rem`,

        maxWidth: 960,
  */

    return (
    <header className="border"
      style={{
        
        background: `url(${src_sfondo}) fixed center no-repeat`,
        backgroundSize: `cover`,
      }}
    >
      <div 
        className="h-100 align-item-center"
        style={{
          margin: `0 auto`,
          backgroundColor: `rgba(0, 0, 0, 0.4)`,
          
         
          
        }}
      >
        {/* <Img fluid={src_sfondo} alt="" /> */}
        <Container fluid className="vh-100">
          <Row className="h-100 align-items-center">
            <Col 
              id={HeaderStyles.DivHeader}
              className="text-center"
              style={{
                opacity: `0`
              }}
            >
                <Link
                  id={HeaderStyles.LinkTitleHeader}
                  to={`/${link_page}`}
                >
                  <h1 >{title}</h1>
                </Link>
            </Col>
            
          </Row>
        </Container>
        {/* <h1 className="text-center"
        style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `black`,
              textDecoration: `none`,
            }}
          >
            {title}
          </Link>
        </h1> */}
  
        
        
      </div>
    </header>
  )
        }
  
Header3.propTypes = {
  title: PropTypes.string,
  src_sfondo: PropTypes.string,
  /* data: PropTypes.element, */
}
  
Header3.defaultProps = {
  title: ``,
  src_sfondo: ``,
}
  
export default Header3
  