import React from "react"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Header3 from "../components/contents/header3"

const link_page = "404"
const NotFoundPage = ({ location }) => (
  <Layout
    header={
      <Header3
        title="404: Not found"
        link_page={link_page}
        location={location}
      />
    }
  >
    <SEO title="404: Not found" />
    <div
      style={{
        textAlign: `center`,
      }}
    >
      <h1>Error page</h1>
      <p>Il percorso non esiste</p>
    </div>
  </Layout>
)

export default NotFoundPage
